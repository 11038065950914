import { OnHoldOrderDetail } from "./OnHoldOrderDetail";
import { OrderDetailNotOnHold } from "./OrderDetailNotOnHold";

export function OrderDetail({ detail, locale, onAccept, onReject }) {

    if (detail.is_on_hold && (detail.approval_status === null || detail.approval_status === '')){
        return <OnHoldOrderDetail 
            detail={detail}
            locale={locale}
            onAccept={onAccept}
            onReject={onReject}
        />
    }
    //
    return <OrderDetailNotOnHold detail={detail} locale={locale}/>;

}
