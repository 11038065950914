
export function GetOrderByUUID(uuid, tenant_id, access_token, completion) {
  console.log('GetOrderByUUID');
  var headers = new Headers();
  const tenant_header = `${tenant_id}`
  headers.set("tenant", tenant_header);
  headers.append("Content-Type", 'application/json');

  if (access_token) {
    const access_token_header = `Bearer ${access_token}`
    headers.set("Authorization", access_token_header);
  }

  var requestOptions = {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
    cache: "no-cache",
  };

  const api_host = process.env.REACT_APP_API_HOST;
  const url = `${api_host}/api/order/detail/bycodapproval/${uuid}`;
  fetch(url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json(); // Parse JSON if content type is application/json
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    })
    .then(responseBody => {
      // Process the parsed JSON object
      completion(responseBody, null);
    })
    .catch(error => {
      console.error('Error fetching or parsing the response:', error);
      completion(null, error);
    });
}