import { Localized } from "../util/localization";


export function Eta({order_detail}) {

    if (order_detail.is_fbo === true){
        const defaultMsg = "This is an express delivery shipment. We will do our best to deliver it within 24 hours (excluding the public holidays) from the time you confirm the order.";
        
        const msg = Localized.EXP_ETA.value(order_detail.locale, defaultMsg)
        return <div>
            {msg}
        </div>
    }
    if (order_detail.eta_date_formatted !== null && order_detail.eta_date_formatted !== ""){
        
        
        const msg = Localized.EXPECTED_DELIVERY_BY_DATE.value(order_detail.locale, "Expected delivery by [expected_delivery_date]", {
            "[expected_delivery_date]": order_detail.eta_date_formatted
        })
        return <div>
            {msg}
        </div>
    }
    return null;
    // {/* check home deliver, show ETA */}
        
    // {/*  if fbo show fbo eta refer os > calulateETADisplayText function, exipry-date 
    // "This is an express delivery shipment. We will do our best to deliver it within 24 hours (excluding the public holidays) from the time you confirm the order."*/}
    // {/*  else eta refer os > calulateETADisplayText function, exipry-date
    // If (TextToDateTimeValidate(OrderData.OrderDate), Replace("Expected delivery by [expected_delivery_date]","[expected_delivery_date]", FormatDateTime(DateTimeToDate(AddDays(TextToDateTime(OrderData.OrderDate),2)), "d MMM yyyy")), "") 
    // */}
}

// ""

// If (TextToDateTimeValidate(OrderData.OrderDate), Replace("Expected delivery by [expected_delivery_date]","[expected_delivery_date]", FormatDateTime(DateTimeToDate(AddDays(TextToDateTime(OrderData.OrderDate),2)), "d MMM yyyy")), "") 