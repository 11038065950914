import { Localized } from "../util/localization";
import { AcceptanceToolbar } from "./AcceptanceToolbar/AcceptanceToolbar";
import { Eta } from "./Eta";
import { OrderSummary } from "./OrderSummary/OrderSummary";


export function OnHoldOrderDetail({detail, locale, onAccept, onReject }){
    return <div className="container acceptance-container">
    <div className="text-center">
        <div className="Container header-title">
            {Localized.ORDER_HEADING.value(locale,"Please Confirm Your Order [order_number]", {
                "[order_number]": detail.order_number
            })}
        </div>

        <div className="header-desc">
            <div className="OSBlockWidget">
                <label className="OSFillParent">
                    <span className="exipry-date" style={{ color: "rgb(149, 155, 160)" }}>
                        {
                            Localized.NEED_CONFIRM.value(locale, "We need your confirmation before we dispatch your cash-on-delivery order")
                        }
                    </span>
                </label>
                <Eta locale={locale} order_detail={detail} />
            </div>

        </div>

        <AcceptanceToolbar 
            locale={locale} 
            order_detail={detail} 
            onAccept={onAccept} 
            onCancel={onReject}/>
    </div>

    <OrderSummary locale={locale} order_detail={detail} />

</div>
}