import { Localized } from "../../util/localization";

export function OrderSummaryRow({ row_data, locale }) {
    if (row_data == null){
        return null;
    }
    return <div className="product-row mob-flex-col">
        <img alt="product" className="product-image" src={ `${row_data.image_url}`}></img>
        <div className="product-row-info-container">
            <span className="product-title OSFillParent">{row_data.name}</span>
            <span className="product-qty">{Localized.QTY.value(locale, "QTY:")} {row_data.quantity}</span>
        </div>
    </div>
}