import { useState } from "react";
import { Localized } from "../../util/localization";
import { CancellationReasonRow } from "./CancellationReasonRow";
import closeIcon from  '../../resources/close.png'

export function CancelPopup({ locale, rejection_reasons, dismiss, onCancelOrderClick }) {
    const [selectedReason, setSelectedReason] = useState(null)
    const [notes, setNotes] = useState(null)

    return <div className="dimBackView" style={{
        position: "absolute", top: 0, right: 0, bottom: 0, left: 0,
    }}>
        <div className="card" style={{
            width: "50%",
            minWidth: "320px",
            padding: "20px"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <span className="reject-title">{
                Localized.CANCELLATION_REASON_HEADING.value(locale, "Please specify reason for cancellation")
                }</span>
                <button style={{
                    background:"none",
                    border:0
                }} onClick={dismiss}>
                    <img alt="rejection reason popup close button" src={closeIcon} width={28} height={28} />
                </button>
            </div>

            <div>
                {rejection_reasons && rejection_reasons.map((reason) => {
                    return <CancellationReasonRow
                        key={reason.name}
                        locale={locale}
                        isSelected={selectedReason != null && reason.name === selectedReason.name}
                        reason_data={reason}
                        onSelect={() => {
                            if (!(reason.is_other_reason === true) && notes) {
                                setNotes(null)
                            }
                            setSelectedReason(reason)
                        }}
                    />
                })}


                {selectedReason && selectedReason.is_other_reason === true && <div className="margin-top-s">
                    <label className="OSFillParent">{
                    Localized.OTHER_REASON.value(locale, "Other Reason")
                    }</label>
                    <textarea onChange={(evnt) => {
                        setNotes(evnt.target.value)
                    }} className="OSFillParent margin-top-s notesInput" maxLength={300}
                        rows="5" />
                </div>}

            </div>

            <div className="margin-top-m">
                <button 
                    className="btn btn-primary" 
                    type="submit" disabled={selectedReason === null || (selectedReason.is_other_reason && (notes === null || notes === ""))}
                    onClick={()=>{
                        onCancelOrderClick(selectedReason, notes)
                    }}
                    >{
                        Localized.CANCEL_ORDER.value(locale, "Cancel the Order")
                }
                </button>
            </div>

        </div>
    </div>
}