import checkedIcon from '../../resources/checked-circle.png'
import uncheckedIcon from '../../resources/uncheck-circle.png'

export function CancellationReasonRow({ isSelected, reason_data, locale, onSelect }) {
    const icon = isSelected ? checkedIcon : uncheckedIcon;
    var reason = reason_data.name;
    var reason_ar = reason_data.name_ar;
    if (locale.toLowerCase() === "ar" && typeof reason_ar === "string" && reason_ar.length > 0) {
        reason = reason_ar
    }
    return <button onClick={onSelect} style={{
        background: "none!important",
        border: "none",
        padding: "0!important",
        color: "#069",
        textDecoration: "underline",
        cursor:"pointer"
    }} className = "rejectionReasonLink" >
    <div className="padding-s display-flex align-items-center" style={{ cursor: "pointer" }}>
        <img alt="checkbox" src={icon} width={14} height={14} />
        <span data-expression="" className="bold margin-left-s OSFillParent">
            {reason}
        </span>
    </div>
    </button >
}