import {Locales} from './localization.js';

export function CountryMapping() {
    const uae_access_token = process.env.REACT_APP_ACCESS_TOKEN_UAE;

    return {
        'uae': {
            'tenant_id': process.env.REACT_APP_TENANT_ID_AE,
            'access_token': uae_access_token,
            'default_locale': Locales.EN,
            'support_number': process.env.REACT_APP_SUPPORT_NUMBER_UAE
        },
        'sa': {
            'tenant_id': process.env.REACT_APP_TENANT_ID_SA,
            'access_token': uae_access_token,
            'default_locale': Locales.AR,
            'support_number': process.env.REACT_APP_SUPPORT_NUMBER_SA
        } 
    }
}