import { Localized, localizedString } from "../../util/localization";
import { OrderSummaryRowList } from "./OrderSummaryRowList";

export function OrderSummary({ locale, order_detail }) {
    if (order_detail.is_on_hold === false) {
        return null;
    }
    return <div>
        <div className="section">
            <div className="section-title dividers" id="b4-b1-Title">
                <div>{
                    Localized.SUMMARY.value(locale, "Order summary")
                }</div>
            </div>

            <div className="section-content">
                <OrderSummaryRowList locale={locale} order_detail={order_detail} />
            </div>

            <div className="text-right order-total__section">
                <div className="order-total">
                    <label>
                        <span className="fw-700">{
                            Localized.TOTAL.value(locale, "Total")
                        }</span>
                        <span className="fw-700 ml-5 blue ThemeGrid_MarginGutter">{order_detail.on_hold_formatted_total}</span>
                    </label>
                </div>
            </div>


            <div className="OSBlockWidget">
                <div className="padding-m order-card">
                    <div style={{ marginTop: "0px", padding: "20px" }}>
                        <div>
                            <label className="OSFillParent">
                                <span className="bold">{
                                    Localized.SHIPPING_METHOD.value(locale, "Shipping Method")
                                }</span>
                            </label>
                        </div>
                        <span>{localizedString(locale, order_detail.shipping_method_name)}</span>
                    </div>

                    <div style={{ marginTop: "0px", padding: "20px" }}>
                        <div>
                            <label className="OSFillParent">
                                <span className="bold">{
                                    Localized.PAYMENT_METHOD.value(locale, "Payment Method")
                                }</span>
                            </label>
                        </div>
                        <span>{
                            Localized.CASH_ON_DELIVERY.value(locale, "Cash on delivery")
                        }</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}