import { Localized } from "../util/localization";

function headerTitle(detail, locale) {
    if (detail.status_code === "cancelled") {
        
        return Localized.ORDER_CANCEL_MSG.value(locale, "Order [order_number] [cancel_message]", {
            "[order_number]": detail.order_number,
            "[cancel_message]": Localized.IS_CANCELLED.value(locale, "is cancelled")
        })
    }

    if (detail.approval_status === "rejected") {
        return Localized.ORDER_CANCEL_MSG.value(locale, "Order [order_number] [cancel_message]", {
            "[order_number]": detail.order_number,
            "[cancel_message]": Localized.WILL_BE_CANCELLED.value(locale, "is cancelled")
        })
    }

    if (detail.approval_status === "accepted") {
        return Localized.ORDER_IS_CONFIRMED.value(locale, "Order [order_number] is Confirmed", {
            "[order_number]": detail.order_number
        })
    }
}

export function OrderDetailNotOnHold({ locale, detail }) {
    return <div className="container acceptance-container">
        <div className="Container header-title">
            {headerTitle(detail, locale)}
        </div>

        <div >
            <span className="font-size-h4">
                {Localized.THANK_YOU.value(locale, "Thank you")}
            </span>
        </div>
    </div>
}