export function UpdateCodApprovalStatus(
  uuid, 
  tenant_id, 
  access_token,
  accepted,
  cancellation_reason, 
  ob_reason_code,
  notes,
  completion){
    console.log('Update COD to Approve');
    var myHeaders = new Headers();
    myHeaders.append("tenant", `${tenant_id}`);
    if (access_token){
      myHeaders.append("Authorization", `Bearer ${access_token}`);
    }
    myHeaders.append("Content-Type", 'application/json');
    console.log(myHeaders);
    const reqBody = {
      status: accepted ? "accepted": "rejected",
      notes,
      cancellation_reason,
      ob_reason_code
    }

    console.log(JSON.stringify(reqBody))

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(reqBody),
        cache: "no-cache", 
    };

    const api_host = process.env.REACT_APP_API_HOST;
    const url = `${api_host}/api/order/cod/update_approval/uuid/${uuid}`;
    fetch(url, requestOptions)
    .then(response => {
        if (response.ok) {
          return response.json(); // Parse JSON if content type is application/json
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      })
      .then(responseBody => {
        // Process the parsed JSON object
        completion(responseBody, null);
      })
      .catch(error => {
        console.error('Error fetching or parsing the response:', error);
        completion(null, error);
      });
}