import { Localized } from "../../util/localization";

export function AcceptanceButton({ detail,locale, onAccept }) {
    return <button
        className="btn btn-primary"
        id="ConfirmOrder" 
        type="button" 
        onClick={onAccept}>
        {Localized.CONFIRM.value(locale, "Confirm")}
    </button>
}