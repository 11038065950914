import logSvg from '../resources/sharafdglogo.svg';
export function Header() {

    return <div style={{
        backgroundColor: "rgb(0, 121, 193)",
        height: "auto"
    }} >
        <div className="container logo-container">
            <img alt="sharaf dg logo" src={logSvg} width={150} />
        </div>
    </div>

}