export const Locales = {
    EN: "en",
    AR: "ar"
};

class LocalizedData {
    constructor(en, ar){
        this.en = en;
        this.ar = ar;
    }

    setPlaceholderValue(template,placeHolders) {
        if (placeHolders){
            for (const key in placeHolders){
                const placeholderVal = placeHolders[key];
                template = template.replace(key, placeholderVal);
            }
        }
        return template
    }

    value(locale, defaultVal, placeholders = null){
        if (locale && locale.toLowerCase() === Locales.AR.toLowerCase() && this.ar){
            return this.setPlaceholderValue(this.ar, placeholders);
        }
        if (this.en){
            return this.setPlaceholderValue(this.en, placeholders);
        }
        return this.setPlaceholderValue(defaultVal, placeholders);
    }
}

export const Localized = {
    ORDER_HEADING: new LocalizedData("Please Confirm Your Order [order_number]", "الرجاء تأكيد طلبك رقم [order_number]"),
    LOADING: new LocalizedData("Loading...", "جاري التحميل"),
    SOME_ERR: new LocalizedData("Some error occurred", "Some error occurred"),
    LOAD_ERR: new LocalizedData("Error occurred while loading details. Try to reload page or contact customer support [support_number]","حدث خطأ أثناء تحميل التفاصيل. حاول إعادة تحميل الصفحة أو اتصل بدعم العملاء [support_number]"),
    RELOAD: new LocalizedData("Reload", "Reload"),
    NEED_CONFIRM: new LocalizedData("We need your confirmation before we dispatch your cash-on-delivery order", "نحن نحتاج منك تأكيد الطلب قبل أن نقوم بتجهيز وإرسال طلبك المدفوع عند الاستلام"),
    CONFIRM: new LocalizedData("Confirm", "تأكيد"),
    CANCEL: new LocalizedData("Cancel", "إلغاء"),
    TOTAL: new LocalizedData("Total", "المجموع"),
    SUMMARY: new LocalizedData("Order summary", "تفاصيل الطلب"),
    QTY: new LocalizedData("QTY:", "الكمية:"),
    CANCELLATION_REASON_HEADING: new LocalizedData("Please specify reason for cancellation", "الرجاء تحديد سبب الإلغاء"),
    OTHER_REASON: new LocalizedData("Other Reason", "أسباب أخرى"),
    CANCEL_ORDER: new LocalizedData("Cancel the Order", "إلغاء الطلب"),
    SHIPPING_INFO: new LocalizedData("Shipping Information", "تفاصيل الشحن"),
    SHIPPING_METHOD: new LocalizedData("Shipping Method", "طريقة الدفع"),
    PAYMENT_METHOD: new LocalizedData("Payment Method", "طريقة الدفع"),
    CASH_ON_DELIVERY: new LocalizedData("Cash on delivery", "الدفع عند الاستلام"),
    ORDER_CANCEL_MSG: new LocalizedData("Order [order_number] [cancel_message]", "الطلب رقم [order_number] [cancel_message]"),
    ORDER_IS_CONFIRMED: new LocalizedData("Order [order_number] is Confirmed", "لقد تم تأكيد الطلب رقم [order_number]"),
    IS_CANCELLED: new LocalizedData(" is cancelled", " تم إلغاؤه"),
    WILL_BE_CANCELLED: new LocalizedData(" will be cancelled", " سوف يلغى"),
    THANK_ACCEPT_MSG: new LocalizedData("Thank you. The team will process and dispatch your order soon", "شكراً لك. سوق يقوم الفريق بتجهيز وإرسال طلبك بأسرع وقت"),
    THANK_YOU: new LocalizedData("Thank you", "شكراً لك"),
    EXP_ETA: new LocalizedData("This is an express delivery shipment. We will do our best to deliver it within 24 hours (excluding the public holidays) from the time you confirm the order.", "هذه الشحنة من ضمن خدمات  التوصيل السريع. سنبذل قصارى جهدنا لتسليمها في غضون 24 ساعة (باستثناء أيام العطل الرسمية) من وقت تأكيد الطلب."),
    EXPECTED_DELIVERY_BY_DATE: new LocalizedData("Expected delivery by [expected_delivery_date]","تاريخ الاستلام المتوقع [expected_delivery_date]"),

    "HOME DELIVERY": new LocalizedData("Home Delivery", "توصيل الى المنزل"),
    "STORE PICKUP": new LocalizedData("Store pickup", "الاستلام من المتجر")
}

export function localizedString(locale,string, placeholders = null){
    var localizedData = Localized[string.toUpperCase()]
    if (localizedData) {
        return localizedData.value(locale, string, placeholders)
    }
    if (placeholders){
        localizedData = new LocalizedData(string, string)
        return localizedData.value(locale, null, placeholders)
    }
    return string
}