import { Header } from "./Header";
export function InvalidPage({message = "Invalid link"}) {
    return (
        <div >
            <Header />
            <div style={{
                padding: "20px",
                display: "flex",
                width: "100%",
                height: "100%",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute", top: 0, right: 0, bottom: 0, left: 0,
                color: "#3b3b3b"
            }}>
                <p style={{
                    fontSize: 24,
                }}>{message}</p>
            </div>

        </div>
    );
}