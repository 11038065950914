import React from "react"
import { InvalidPage } from "./components/InvalidPage";
import './App.css'
import { AcceptanceForUUID } from "./components/AcceptanceForUUID";
import { CountryMapping } from "./util/country_mapping";
import { Locales, Localized } from './util/localization';

export function Acceptance() {
    const queryParameters = new URLSearchParams(window.location.search);
    const uuid = queryParameters.get("uuid");
    const country = queryParameters.get("country");
    const mapping = CountryMapping();
    const countryConfig = country ? mapping[country]: null
    console.log('countryConfig :', countryConfig);
    if (uuid && countryConfig){
        return <AcceptanceForUUID 
            uuid={uuid} 
            tenant_id={countryConfig.tenant_id}
            access_token={countryConfig.access_token}
            support_number={countryConfig.support_number}
        />;
    }

    var locale = Locales.EN;
    if (countryConfig && countryConfig.default_locale){
        locale = countryConfig.default_locale
    }

    return <InvalidPage message={Localized.SOME_ERR.value(locale, 'Some error occured')}/>
}